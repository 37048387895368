<template>
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.5455 34.0826L57.3424 17.6764C56.9452 16.9794 56.3877 16.543 55.5076 16.543H14.492C13.6345 16.543 13.0611 16.9684 12.6572 17.6764L4.4541 34.0826C4.0743 34.8893 4.20432 35.6999 4.74258 36.3467L33.4535 69.2959C33.8432 69.743 34.4065 70 34.9998 70C35.5932 70 36.1564 69.743 36.5461 69.2959L65.257 36.3467C65.7919 35.7031 65.9276 34.8935 65.5455 34.0826ZM52.1895 20.6445L46.9545 31.1145L39.1014 20.6445H52.1895ZM43.2029 32.9492H26.7967L34.9998 22.0117L43.2029 32.9492ZM30.8982 20.6445L23.0451 31.1145L17.8102 20.6445H30.8982ZM14.492 23.1793L19.377 32.9492H9.60703L14.492 23.1793ZM10.7965 37.0508H21.3402L31.4506 60.7551L10.7965 37.0508ZM34.9998 58.6209L25.8 37.0508H44.1996L34.9998 58.6209ZM38.549 60.7551L48.6594 37.0508H59.2031L38.549 60.7551ZM50.6227 32.9492L55.5076 23.1793L60.3926 32.9492H50.6227Z"
      fill="#4874E6"
    />
    <path
      d="M35 0C33.8674 0 32.9492 0.918203 32.9492 2.05078V6.28906C32.9492 7.42164 33.8674 8.33984 35 8.33984C36.1326 8.33984 37.0508 7.42164 37.0508 6.28906V2.05078C37.0508 0.918203 36.1326 0 35 0Z"
      fill="#4874E6"
    />
    <path
      d="M26.2995 9.04386L23.3993 6.14364C22.5986 5.34274 21.3 5.34274 20.4991 6.14364C19.6982 6.94454 19.6982 8.24296 20.4991 9.04386L23.3993 11.9441C24.2001 12.745 25.4988 12.7451 26.2995 11.9441C27.1004 11.1432 27.1004 9.84476 26.2995 9.04386Z"
      fill="#4874E6"
    />
    <path
      d="M49.5007 6.14364C48.7 5.34274 47.4014 5.34274 46.6005 6.14364L43.7003 9.04386C42.8994 9.84476 42.8994 11.1432 43.7003 11.9441C44.501 12.745 45.7997 12.7451 46.6005 11.9441L49.5007 9.04386C50.3016 8.24296 50.3016 6.94454 49.5007 6.14364Z"
      fill="#4874E6"
    />
  </svg>
</template>

<script>
export default {
  name: "DiamondIcon",
};
</script>

<style scoped></style>
